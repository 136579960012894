<template>
  <div class="chart chart-sample_graph">
    <apexchart
      height="324px"
      :series="series"
      :options="options"
    ></apexchart>
  </div>
</template>

<script>
import {
  formatDuration,
  roundNumber,
  zipArrays,
} from '@/helpers';

import moment from 'moment';

export default {
  name: 'SampleGraphData',
  components: {},
  props: {
    data: Object,
    startTs: Number,
  },
  computed: {
    series() {
      return [{
        name: 'Mass (g)',
        type: 'line',
        data: zipArrays(
          this.data.periods,
          this.data.periodGrams,
        ),
      }, {
        name: 'Rate of Mass Loss (g/min)',
        type: 'line',
        data: zipArrays(
          this.data.periods,
          this.data.periodLosses,
        ),
        yaxis: 1,
      }];
    },
    weightMin() {
      return Math.min(...this.rangeGrams);
    },
    weightMax() {
      return Math.max(...this.rangeGrams);
    },
  },
  data() {
    return {
      rangeGrams: [],
      rangeLosses: [],
      options: {
        chart: {
          type: 'line',
          height: 400,
          events: {
            beforeZoom: (chartContext, { xaxis }) => {
              this.onZoom(chartContext, { xaxis });
            },
            beforeResetZoom: () => {
              this.resetRange();
            },
          },
          toolbar: {
            tools: {
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
        },
        stroke: {
          width: [2, 1],
          curve: 'smooth',
        },
        colors: [
          '#0a5bcc',
          '#ff0c0c',
        ],
        xaxis: {
          type: 'numeric',
          title: {
            text: 'Time (min)',
          },
          labels: {
            formatter: (value) => (formatDuration(value * this.data.periodInterval)),
          },
          tooltip: {
            enabled: false,
          },
        },
        legend: {
          show: false,
        },
        yaxis: [{
          title: {
            text: 'Mass (g)',
          },
          min: () => this.weightMin,
          max: () => this.weightMax,
          labels: {
            formatter: (value) => (roundNumber(value, 2, 2)),
          },
        }, {
          opposite: true,
          title: {
            text: 'Rate of Mass Loss (g/min)',
          },
          max: () => {
            const min = Math.min(...this.rangeLosses);
            const max = Math.max(...this.rangeLosses);
            const corner = (max + min) / 4;
            return max - corner;
          },
          min: () => {
            const min = Math.min(...this.rangeLosses);
            const max = Math.max(...this.rangeLosses);
            const corner = (max + min) / 4;
            return min + corner;
          },
          labels: {
            formatter: (value) => (roundNumber(value, 2, 2)),
          },
        }],
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            formatter: (_, { dataPointIndex }) => {
              const period = this.data.periods[dataPointIndex];
              const pointTaken = period * this.data.periodInterval;
              const pointDuration = formatDuration(pointTaken);
              const pointTs = (this.startTs + pointTaken) * 1000;
              const pointDate = moment(pointTs).format('DD.MM.YYYY HH:mm');
              return `
                <div class="flex justify-between gap-2">
                  <div>${pointDuration}</div>
                  <div>${pointDate}</div>
                </div>
              `;
            },
          },
        },
        markers: {
          size: 0,
        },
      },
    };
  },
  methods: {
    resetRange() {
      this.rangeGrams = this.data.periodGrams;
      this.rangeLosses = this.data.periodLosses;
    },
    getVisibleData(chartContext, seriesIndex, xaxis) {
      const { min, max } = xaxis;
      if (min === undefined || max === undefined) {
        return chartContext.w.globals.series[seriesIndex];
      }
      return chartContext.w.globals.series[seriesIndex].filter((dataPoint, index) => {
        const xValue = chartContext.w.globals.seriesX[seriesIndex][index];
        return xValue >= Math.floor(min) && xValue <= Math.ceil(max);
      });
    },
    onZoom(chartContext, { xaxis }) {
      const visibleGrams = this.getVisibleData(chartContext, 0, xaxis);
      const visibleLosses = this.getVisibleData(chartContext, 1, xaxis);
      this.rangeGrams = visibleGrams;
      this.rangeLosses = visibleLosses;
    },
  },
  created() {
    this.resetRange();
  },
};
</script>

<style lang="scss">
.chart-sample_graph .apexcharts-tooltip {
  top: -80px !important;
}
</style>
